import React from "react"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const Contact = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Contact Us" />
      <div className="mb-5">
        <PageTitle title="Contact Us" />
        <div className="col-12 col-md-8 offset-md-2">
          <form name="Contact Us" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="Contact Us" />
            <div className="form-group">
              <label htmlFor="Name">Your Name (required)</label>
              <input
                className="form-control"
                type="text"
                id="Name"
                name="Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Email">Your Email (required)</label>
              <input
                className="form-control"
                type="email"
                id="Email"
                name="Email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Message">Message (required)</label>
              <textarea
                className="form-control"
                id="Message"
                name="Message"
                rows="5"
                required
              />
            </div>
            <button className="btn btn-primary" type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
