import React from "react"

const PageTitle = ({ title }) => {
  return (
    <header className="entry-header">
      <h1 className="entry-title mb-4">{title}</h1>
    </header>
  )
}

export default PageTitle
